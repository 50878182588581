














































import Vue, { PropType } from "vue";
import CandidateEmployeeCard from "@/components/recruiter/CandidateEmployeeCard.vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { format_overall_score } from "@/utils/global";
import { SearchedCandidate } from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "ViewCandidatesBody",
  methods: { format_overall_score },
  components: { DataNotFound, CandidateEmployeeCard },
  data() {
    return {
      candidates: [] as SearchedCandidate[]
    };
  },
  props: {
    matching_candidates: {
      type: Array as PropType<SearchedCandidate[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    job_id: {
      type: Number
    },
    disable_favorites_action: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    matching_candidates(n) {
      if (n.length > 0) {
        this.candidates = this.matching_candidates.filter(
          (val: SearchedCandidate) => val
        );
      } else this.candidates = [];
    }
  }
});
